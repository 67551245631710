import Swiper from 'swiper';
export function index(){
	const fv = document.getElementById('fvslide');

	if(fv){
        const ttl = document.getElementById('fvttl');

    	const materialTxt = document.getElementById('fvtxt');
    	const materialAry = Array();
    	for(let i = 0; i < materialTxt.children.length; i++){
    		materialAry[i] = materialTxt.children[i].innerHTML;
    	}

        function resize(){
			let el = document.getElementsByClassName('swiper-slide');
			for(let i = 0; i < el.length; i++){
                el[i].style.maxHeight = window.innerHeight + 'px';
            }
        }

		const swiper = new Swiper('#fvslide', {
			spaceBetween: 0,
			effect: 'fade',
			speed: 2000,
			loop: true,
			autoplay: {
				delay: 8000,
				disableOnInteraction: false,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			navigation: {
				nextEl: '.controll__next',
				prevEl: '.controll__prev',
			},
			on: {
				init: function(){
					if(ttl){
						ttl.innerHTML = materialAry[0];
						setTimeout(function(){
							ttl.classList.add('fade');
						},800);
					}
                    resize();
    			},
				transitionStart: function(){
					ttl.classList.remove('fade');
				},
				transitionEnd: function(){
					ttl.innerHTML = materialAry[this.realIndex];
					ttl.classList.add('fade');
				}
  			},
		});
	}
}

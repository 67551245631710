import {scrolEvents} from './default';
import {vues} from './vues';
import {index} from './index';
import {asunaro} from './asunaro';
import {meijyu} from './meijyu';
import {loading} from './loading';

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();


    // LOADING 処理
    const keyName = 'visited';
    const keyValue = true;

    if (!sessionStorage.getItem(keyName)) {
        sessionStorage.setItem(keyName, keyValue);
        loading();
    } else {
        const intro = document.getElementById('intro');
        if(intro){
            intro.remove();
        }
        index();
    }

	vues();
    scrolEvents(controller , scene);
    asunaro(controller , scene);
    meijyu(controller , scene);

});

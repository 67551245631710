export function meijyu(controller , scene){

    if(document.getElementById('meijyu-header')){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * 1.5
            })
            //.addIndicators()
            .setClassToggle('#meijyu-header','active').addTo(controller)
        )
    }

    

}

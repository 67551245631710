import {faq} from './component/faq';

export function vues(){
	const h = new Vue({
		el: '#site-header',
		data: {
			flag: false,
		}
	});

	const v = new Vue({
		el: '#app',
		components: {
			'faq': faq(),
		}
	});

	const m = new Vue({
		el: '#meijyu-contact',
        data: {
            toggle: true
        }
	});
}

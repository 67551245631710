import Swiper from 'swiper';
export function asunaro(controller , scene){
	
	if(document.getElementById('asunaro-header')){
		scene.push(
			new ScrollMagic.Scene({
				triggerElement: 'body',
				offset: window.innerHeight * 1.5
			})
			.setClassToggle('#asunaro-header','active').addTo(controller)
		)
	}
	
    let asunaroSwipe = document.getElementById('asunaro-swipe');
    if(asunaroSwipe){
        new Swiper('#asunaro-swipe', {
            spaceBetween: 0,
            effect: 'fade',
            speed: 2000,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            }
        });
    }

}
export function faq(){
	const f = {
		data: function(){
			return {
				toggle: false,
			}
		},
		template: 
		`
          <dl @click="toggle = !toggle" :class="{'active': toggle}">
              <dt v-html="q"></dt>
              <dd v-html="a" v-show="toggle"></dd>
          </dl>
		`,
		props: ['q','a']
	}
	return f;
}